import React from "react"

import Layout from "../components/Layout"
import PrivacyPolicy from "../components/PrivacyPolicy"
import SEO from "../components/seo"

const PrivacyPolicyPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Privacy Policy" />
    <PrivacyPolicy />
  </Layout>
)

export default PrivacyPolicyPage
